import React from "react";
import GenericForm from "../_generic-form";
import { checkAbility, checkAuthorization } from "../../_helpers";
import { modulos } from "../../utils";

// const categories = ['LENTILLE SOUPLE', 'LENTILLE TORIQUE SOUPLE', 'LENTILLE RIGIDE', 'LENTILLE TORIQUE RIGIDE', 'LENTILLE SCLERALE', 'LENTILLE COULEUR', 'LENTILLE COULEUR TORIQUE']
const categories = [
	"LENTILLE SOUPLE",
	"LENTILLE TORIQUE SOUPLE",
	"LENTILLE RIGIDE",
	"LENTILLE TORIQUE RIGIDE",
	"LENTILLE COULEUR",
	"LENTILLE SCLERALE",
	"LENTILLE COULEUR TORIQUE",
];

let normstest = {
	AXELLE38: {
		ref: "AXELLE38",
		chipcolor: "axelle38 disabled",
		blue: true,
		description: "",
		category: "LENTILLE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.3, 8.4, 8.6, 8.7].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["", "8.30", "8.40", "8.60", "8.70"],
				default: "",
				// default: '8.30'
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.00"],
				default: "14.00",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},
	AXELLE38PRS: {
		ref: "AXELLE38PRS",
		chipcolor: "axelle38prs disabled",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 9.2 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) => {
					return value &&
						value.length > 0 &&
						!isNaN(value) &&
						value >= 0 &&
						value <= 15.0 &&
						modulos(value, 0.1) == 0
						? false
						: true;
				},
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				default: "",
				placeholder: "7.80",
				// default: '7.80',
				step: 0.1,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				default: "",
				placeholder: "14.00",
				// default: '14.00',
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},
	AXELLE70: {
		ref: "AXELLE70",
		chipcolor: "axelle70",
		blue: true,
		description: "",
		category: "LENTILLE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.4, 8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["", "8.40", "8.60"],
				default: "",
				// default: '8.40'
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.00"],
				default: "14.00",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},
	AXELLE70PRS: {
		ref: "AXELLE70PRS",
		chipcolor: "axelle70prs",
		description: "",
		check: true,
		blue: true,
		category: "LENTILLE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 9.2 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 0 &&
					value <= 15.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				default: "",
				placeholder: "7.80",
				// default: '7.80',
				step: 0.1,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				default: "",
				// default: '14.00',
				placeholder: "14.00",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},
	TORIQUE70: {
		ref: "TORIQUE70",
		chipcolor: "axelletorique70",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE TORIQUE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 9.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				values: [
					"",
					"8.00",
					"8.10",
					"8.20",
					"8.30",
					"8.40",
					"8.50",
					"8.60",
					"8.70",
					"8.80",
					"8.90",
					"9.00",
				],
				// default: '8.30',
				// step: .1
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				values: ["14.00"],
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "14.00",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				// default: '',
				default: "1",
				step: 1,
			},
		},
	},
	TORIQUE38: {
		ref: "TORIQUE38",
		chipcolor: "axelletorique38 disabled",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE TORIQUE SOUPLE",
		errors: {
			r: [
				(value) => {
					return value &&
						value.length > 0 &&
						!isNaN(value) &&
						value >= 8.3 &&
						value <= 8.7 &&
						modulos(value, 0.1) == 0
						? false
						: true;
				},
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				values: ["", "8.30", "8.40", "8.50", "8.60", "8.70"],
				// default: '8.30',
				// step: .1
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				values: ["14.00"],
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "14.00",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				placeholder: "-0.25",
				// default: '-0.50',
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	TORIQUE38PRS: {
		ref: "TORIQUE38PRS",
		chipcolor: "axelletorique38prs disabled",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE TORIQUE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8 &&
					value <= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				values: [
					"",
					"8.00",
					"8.10",
					"8.20",
					"8.80",
					"8.90",
					"9.00",
				],
				// default: '8.30',
				// step: .1
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				values: ["14.00"],
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "14.00",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				// default: '',
				default: "1",
				step: 1,
			},
		},
	},
	TORIQUE70PRS: {
		ref: "TORIQUE70PRS",
		chipcolor: "axelletorique70prs ",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE TORIQUE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8 &&
					value <= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				values: [
					"",
					"8.00",
					"8.10",
					"8.20",
					"8.80",
					"8.90",
					"9.00",
				],
				// default: '8.30',
				// step: .1
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				values: ["14.00"],
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "14.00",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				// default: '',
				default: "1",
				step: 1,
			},
		},
	},

	DK18: {
		ref: "DK18",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.0 &&
					value <= 8.7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '7.00',
				placeholder: "7.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '8.00',
				placeholder: "8.00",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			/*
            e: {
                name: "e",
                label: "Edge Lift", 
                wrapper: {tag: "div", attrs: {className: "col-sm-4 hidden"}},
                type: "number",
                default: '',
                placeholder: '0.00',
                step: 0.5
            },
            */
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	DK36: {
		ref: "DK36",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.0 &&
					value <= 8.7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '7.00',
				placeholder: "7.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				placeholder: "8.00",
				// default: '8.00',
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	DK92: {
		ref: "DK92",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.0 &&
					value <= 8.7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '7.00',
				placeholder: "7.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '8.00',
				placeholder: "8.00",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	DK100: {
		ref: "DK100",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.0 &&
					value <= 8.7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '7.00',
				placeholder: "7.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '8.00',
				placeholder: "8.00",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	KERA18: {
		ref: "KERA18",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 5.0 &&
					value <= 7.6 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '5.00',
				placeholder: "5.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '7.80',
				placeholder: "7.80",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	KERA36: {
		ref: "KERA36",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 5.0 &&
					value <= 7.6 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '5.00',
				placeholder: "5.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '7.80',
				placeholder: "7.80",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	KERA92: {
		ref: "KERA92",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 5.0 &&
					value <= 7.6 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				placeholder: "5.00",
				// default: '5.00',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				placeholder: "7.80",
				// default: '7.80',
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	KERA100: {
		ref: "KERA100",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 5.0 &&
					value <= 7.6 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '5.00',
				placeholder: "5.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '7.80',
				placeholder: "7.80",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	// LENTILLES COULEURS

	GIALLO: {
		ref: "GIALLO",
		description: "",
		chipcolor: "giallo",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	PERLA: {
		ref: "PERLA",
		description: "",
		chipcolor: "perla",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	SPICE: {
		ref: "SPICE",
		description: "",
		chipcolor: "spice disabled",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	VERDE: {
		ref: "VERDE",
		description: "",
		chipcolor: "verde",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	GIALLOPLAN: {
		ref: "GIALLOPLAN",
		description: "",
		chipcolor: "giallo",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "select",
				values: ["0.00"],
				default: "0.00",
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	PERLAPLAN: {
		ref: "PERLAPLAN",
		description: "",
		chipcolor: "perla",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "select",
				values: ["0.00"],
				default: "0.00",
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	SPICEPLAN: {
		ref: "SPICEPLAN",
		description: "",
		chipcolor: "spice disabled",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "select",
				values: ["0.00"],
				default: "0.00",
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	VERDEPLAN: {
		ref: "VERDEPLAN",
		description: "",
		chipcolor: "verde",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "select",
				values: ["0.00"],
				default: "0.00",
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	// Done by Yacine
	SCLERALE: {
		ref: "SCLERALE",
		chipcolor: "sclerale disabled",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE SCLERALE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 9 &&
					value >= 7 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[15.5, 16.5].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sag1: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 4.0 &&
					value >= -5.6,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		} /*
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: { tag: "div", attrs: { className: "col-sm-4" } },
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.1,
			},
			sag1: {
				name: "sag1",
				label: "Sagittal",
				type: "number",
				wrapper: { tag: "div", attrs: { className: "col-sm-4" } },
				default: "",
				placeholder: "0.0",
				// default: '7.80',
				step: 0.001,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["", "15.50", "16.50"],
				default: "",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: { tag: "div", attrs: { className: "col-sm-4" } },
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: { tag: "div", attrs: { className: "col-sm-4" } },
				type: "number",
				default: "1",
			},
		}, */,
		limits: {},
	},

	"SCLERALE TORIQUE": {
		ref: "SCLERALE TORIQUE",
		chipcolor: "sclerale disabled",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE SCLERALE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 9 &&
					value >= 7 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[15.5, 16.5].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sag1: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 4.0 &&
					value >= -5.6,
			],
			tor: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[50, 100, 150, 200].indexOf(Number(value)) > -1
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		/*
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 sclerale-torique" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.1,
			},
			sag1: {
				name: "sag1",
				label: "Sagittal",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 sclerale-torique" },
				},
				default: "",
				placeholder: "0.0",
				// default: '7.80',
				step: 0.001,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-sm-4" } },
				values: ["", "15.50", "16.50"],
				default: "",
			},
			tor: {
				name: "tor",
				label: "Toricité",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-sm-4" } },
				values: ["", "50", "100", "150", "200"],
				default: "",
				// default: '8.30'
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 sclerale-torique" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 sclerale-torique" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 sclerale-torique" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 sclerale-torique" },
				},
				type: "number",
				default: "1",
			},
		}, */
		limits: {},
	},

	// LENTILLE TORIQUE RIGIDE

	"T.IN100": {
		ref: "TORIQUE INTERNE 100",
		description: "",
		check: true,
		chipcolor: "sclerale disabled top",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {},
	},

	"T.EXT100": {
		ref: "TORIQUE EXTERNE 100",
		description: "",
		check: true,
		chipcolor: "sclerale disabled top",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 8 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {},
	},

	B100: {
		ref: "BITORIQUE 100",
		description: "",
		check: true,
		chipcolor: "sclerale disabled top",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 8 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {},
	},

	"T.IN125": {
		ref: "TORIQUE INTERNE 125",
		description: "",
		check: true,
		chipcolor: "sclerale bottom",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon 1",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"T.EXT125": {
		ref: "TORIQUE EXTERNE 125",
		description: "",
		check: true,
		chipcolor: "sclerale bottom",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			/* r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			], */
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			/*
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			*/
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},

			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},

			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	B125: {
		ref: "BITORIQUE 125",
		description: "",
		check: true,
		chipcolor: "sclerale bottom",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon 1",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},

			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},

			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	// COULEURS TORIQUE

	"GIALLO TORIQUE": {
		ref: "GIALLO TORIQUE",
		description: "",
		chipcolor: "giallo",
		category: "LENTILLE COULEUR TORIQUE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				values: ["14.20"],
				default: "14.20",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"PERLA TORIQUE": {
		ref: "PERLA TORIQUE",
		description: "",
		chipcolor: "perla",
		category: "LENTILLE COULEUR TORIQUE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				values: ["14.20"],
				default: "14.20",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"SPICE TORIQUE": {
		ref: "SPICE TORIQUE",
		description: "",
		chipcolor: "spice disabled",
		category: "LENTILLE COULEUR TORIQUE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				values: ["14.20"],
				default: "14.20",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"VERDE TORIQUE": {
		ref: "VERDE TORIQUE",
		description: "",
		chipcolor: "verde",
		category: "LENTILLE COULEUR TORIQUE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				values: ["14.20"],
				default: "14.20",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},
};

let norms = {
	AXELLE38: {
		ref: "AXELLE38",
		chipcolor: "axelle38 disabled",
		blue: true,
		description: "",
		category: "LENTILLE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.3, 8.4, 8.6, 8.7].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {},
	},
	AXELLE38PRS: {
		ref: "AXELLE38PRS",
		chipcolor: "axelle38prs disabled",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 9.2 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) => {
					return value &&
						value.length > 0 &&
						!isNaN(value) &&
						value >= 0 &&
						value <= 15.0 &&
						modulos(value, 0.1) == 0
						? false
						: true;
				},
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {},
	},
	AXELLE70: {
		ref: "AXELLE70",
		chipcolor: "axelle70",
		blue: true,
		description: "",
		category: "LENTILLE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.4, 8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["", "8.40", "8.60"],
				default: "",
				// default: '8.40'
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.00"],
				default: "14.00",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},
	AXELLE70PRS: {
		ref: "AXELLE70PRS",
		chipcolor: "axelle70prs",
		description: "",
		check: true,
		blue: true,
		category: "LENTILLE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 9.2 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 0 &&
					value <= 15.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				default: "",
				placeholder: "7.80",
				// default: '7.80',
				step: 0.1,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				default: "",
				// default: '14.00',
				placeholder: "14.00",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},
	TORIQUE70: {
		ref: "TORIQUE70",
		chipcolor: "axelletorique70",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE TORIQUE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 9.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				values: ["", "8.30", "8.40", "8.50", "8.60", "8.70"],
				// default: '8.30',
				// step: .1
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				values: ["14.00"],
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "14.00",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				// default: '',
				default: "1",
				step: 1,
			},
		},
	},
	TORIQUE38: {
		ref: "TORIQUE38",
		chipcolor: "axelletorique38 disabled",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE TORIQUE SOUPLE",
		errors: {
			r: [
				(value) => {
					return value &&
						value.length > 0 &&
						!isNaN(value) &&
						value >= 8.3 &&
						value <= 8.7 &&
						modulos(value, 0.1) == 0
						? false
						: true;
				},
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {},
	},
	TORIQUE38PRS: {
		ref: "TORIQUE38PRS",
		chipcolor: "axelletorique38prs disabled",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE TORIQUE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8 &&
					value <= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {},
	},
	TORIQUE70PRS: {
		ref: "TORIQUE70PRS",
		chipcolor: "axelletorique70prs ",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE TORIQUE SOUPLE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8 &&
					value <= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.0].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				values: [
					"",
					"8.00",
					"8.10",
					"8.20",
					"8.30",
					"8.40",
					"8.50",
					"8.60",
					"8.70",
					"8.80",
					"8.90",
					"9.00",
				],

				// default: '8.30',
				// step: .1
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				values: ["14.00"],
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "14.00",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				// default: '',
				default: "1",
				step: 1,
			},
		},
	},

	DK18: {
		ref: "DK18",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.0 &&
					value <= 8.7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.00',
				placeholder: "7.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '8.00',
				placeholder: "8.00",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			/*
            e: {
                name: "e",
                label: "Edge Lift", 
                wrapper: {tag: "div", attrs: {className: "col-sm-4 hidden"}},
                type: "number",
                default: '',
                placeholder: '0.00',
                step: 0.5
            },
            */
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	DK36: {
		ref: "DK36",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.0 &&
					value <= 8.7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.00',
				placeholder: "7.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "8.00",
				// default: '8.00',
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	DK92: {
		ref: "DK92",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.0 &&
					value <= 8.7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.00',
				placeholder: "7.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '8.00',
				placeholder: "8.00",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	DK100: {
		ref: "DK100",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.0 &&
					value <= 8.7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 8.0 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.00',
				placeholder: "7.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '8.00',
				placeholder: "8.00",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	KERA18: {
		ref: "KERA18",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 5.0 &&
					value <= 7.6 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '5.00',
				placeholder: "5.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.80',
				placeholder: "7.80",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	KERA36: {
		ref: "KERA36",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 5.0 &&
					value <= 7.6 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '5.00',
				placeholder: "5.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.80',
				placeholder: "7.80",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	KERA92: {
		ref: "KERA92",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 5.0 &&
					value <= 7.6 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "5.00",
				// default: '5.00',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "7.80",
				// default: '7.80',
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	KERA100: {
		ref: "KERA100",
		chipcolor: "dkkera",
		category: "LENTILLE RIGIDE",
		check: true,
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 5.0 &&
					value <= 7.6 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 7.8 &&
					value <= 11.0 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 50 &&
					value >= -50 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			e: [
				(value) =>
					isNaN(value) ||
					value.length == 0 ||
					(value &&
						value.length > 0 &&
						!isNaN(value) &&
						value <= 1 &&
						value >= -1 &&
						modulos(value, 0.5) == 0)
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '5.00',
				placeholder: "5.00",
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '7.80',
				placeholder: "7.80",
				step: 0.1,
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	// LENTILLES COULEURS

	GIALLO: {
		ref: "GIALLO",
		description: "",
		chipcolor: "giallo",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	PERLA: {
		ref: "PERLA",
		description: "",
		chipcolor: "perla",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	SPICE: {
		ref: "SPICE",
		description: "",
		chipcolor: "spice disabled",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	VERDE: {
		ref: "VERDE",
		description: "",
		chipcolor: "verde",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	GIALLOPLAN: {
		ref: "GIALLOPLAN",
		description: "",
		chipcolor: "giallo",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "select",
				values: ["0.00"],
				default: "0.00",
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	PERLAPLAN: {
		ref: "PERLAPLAN",
		description: "",
		chipcolor: "perla",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "select",
				values: ["0.00"],
				default: "0.00",
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	SPICEPLAN: {
		ref: "SPICEPLAN",
		description: "",
		chipcolor: "spice disabled",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "select",
				values: ["0.00"],
				default: "0.00",
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	VERDEPLAN: {
		ref: "VERDEPLAN",
		description: "",
		chipcolor: "verde",
		category: "LENTILLE COULEUR",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			p: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["14.20"],
				default: "14.20",
			},
			p: {
				name: "p",
				label: "Puissance",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "select",
				values: ["0.00"],
				default: "0.00",
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: { tag: "div", attrs: { className: "col-3" } },
				type: "number",
				default: "1",
			},
		},
	},

	// Done by Yacine
	SCLERALE: {
		ref: "SCLERALE",
		chipcolor: "sclerale",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE SCLERALE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 9 &&
					value >= 7 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[15.5, 16.5].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sag1: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 4.0 &&
					value >= -5.6,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.1,
			},
			sag1: {
				name: "sag1",
				label: "Sagittal",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				placeholder: "0.0",
				// default: '7.80',
				step: 0.001,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: { tag: "div", attrs: { className: "col-3" } },
				values: ["", "15.50", "16.50"],
				default: "",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"SCLERALE TORIQUE": {
		ref: "SCLERALE TORIQUE",
		chipcolor: "sclerale",
		check: true,
		blue: true,
		description: "",
		category: "LENTILLE SCLERALE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 9 &&
					value >= 7 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[15.5, 16.5].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sag1: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 4.0 &&
					value >= -5.6,
			],
			tor: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[50, 100, 150, 200].indexOf(Number(value)) > -1
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.1,
			},
			sag1: {
				name: "sag1",
				label: "Sagittal",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				placeholder: "0.0",
				// default: '7.80',
				step: 0.001,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				values: ["", "15.50", "16.50"],
				default: "",
			},
			tor: {
				name: "tor",
				label: "Toricité",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				values: ["", "50", "100", "150", "200"],
				default: "",
				// default: '8.30'
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	// LENTILLE TORIQUE RIGIDE
	"T.IN42": {
		ref: "TORIQUE INTERNE 42",
		description: "",
		check: true,
		chipcolor: "sclerale top",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon 1",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"T.EXT42": {
		ref: "TORIQUE EXTERNE 42",
		description: "",
		check: true,
		chipcolor: "sclerale top",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],

			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},

			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},

			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},

			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	B42: {
		ref: "BITORIQUE 42",
		description: "",
		check: true,
		chipcolor: "sclerale top",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon 1",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},

			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},

			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"T.IN100": {
		ref: "TORIQUE INTERNE 100",
		description: "",
		check: true,
		chipcolor: "sclerale secondrow",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon 1",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"T.EXT100": {
		ref: "TORIQUE EXTERNE 100",
		description: "",
		check: true,
		chipcolor: "sclerale secondrow",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],

			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},

			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},

			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},

			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	B100: {
		ref: "BITORIQUE 100",
		description: "",
		check: true,
		chipcolor: "sclerale secondrow",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon 1",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},

			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},

			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"T.IN125": {
		ref: "TORIQUE INTERNE 125",
		description: "",
		check: true,
		chipcolor: "sclerale bottom",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon 1",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"T.EXT125": {
		ref: "TORIQUE EXTERNE 125",
		description: "",
		check: true,
		chipcolor: "sclerale bottom",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			/* r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			], */
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			/*
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			*/
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},

			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},

			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	B125: {
		ref: "BITORIQUE 125",
		description: "",
		check: true,
		chipcolor: "sclerale bottom",
		category: "LENTILLE TORIQUE RIGIDE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			r2: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 8.7 &&
					value >= 7 &&
					modulos(value, 0.05) == 0
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 11 &&
					value >= 9 &&
					modulos(value, 0.1) == 0
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= -0.25 &&
					value >= -6.5 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],

			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon 1",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			r2: {
				name: "r2",
				label: "Rayon 2",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "7.00",
				// default: '7.80',
				step: 0.05,
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				placeholder: "9.00",
				// default: '7.80',
				step: 0.1,
			},
			sph: {
				name: "sph",
				label: "Sphère",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "",
				placeholder: "0.00",
				// default: '0.00',
				step: 0.25,
			},

			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},

			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},

			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-4 large-label" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	// COULEURS TORIQUE

	"GIALLO TORIQUE": {
		ref: "GIALLO TORIQUE",
		description: "",
		chipcolor: "giallo",
		category: "LENTILLE COULEUR TORIQUE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["14.20"],
				default: "14.20",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"PERLA TORIQUE": {
		ref: "PERLA TORIQUE",
		description: "",
		chipcolor: "perla",
		category: "LENTILLE COULEUR TORIQUE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["14.20"],
				default: "14.20",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},

	"SPICE TORIQUE": {
		ref: "SPICE TORIQUE",
		description: "",
		chipcolor: "spice disabled",
		category: "LENTILLE COULEUR TORIQUE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["14.20"],
				default: "14.20",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
	"VERDE TORIQUE": {
		ref: "VERDE TORIQUE",
		description: "",
		chipcolor: "verde",
		category: "LENTILLE COULEUR TORIQUE",
		errors: {
			r: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[8.6].indexOf(Number(value)) > -1
						? false
						: true,
			],
			d: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					[14.2].indexOf(Number(value)) > -1
						? false
						: true,
			],
			sph: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					value <= 25 &&
					value >= -25 &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			cyl: [
				(value) =>
					value &&
					value.length > 0 &&
					(value.startsWith("-") ||
						value.startsWith("+") ||
						value.startsWith("0.00")) &&
					/\.\d{2}$/.test(value) &&
					!isNaN(value) &&
					((value <= -0.25 && value >= -6.5) ||
						(value >= +0.25 && value <= +6.5)) &&
					modulos(value, 0.25) == 0
						? false
						: true,
			],
			axe: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value <= 180 &&
					value >= 0 &&
					value.indexOf(".") < 0 &&
					modulos(value, 5) == 0
						? false
						: true,
			],
			qty: [
				(value) =>
					value &&
					value.length > 0 &&
					!isNaN(value) &&
					value >= 1 &&
					value.indexOf(".") == -1 &&
					modulos(value, 1) == 0
						? false
						: true,
			],
		},
		limits: {
			r: {
				name: "r",
				label: "Rayon",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["8.60"],
				default: "8.60",
			},
			d: {
				name: "d",
				label: "Diametre",
				type: "select",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				values: ["14.20"],
				default: "14.20",
			},
			sph: {
				name: "sph",
				label: "Sphère",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0.00',
				placeholder: "0.00",
				step: 0.25,
			},
			cyl: {
				name: "cyl",
				label: "Cyl",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '-0.50',
				placeholder: "-0.25",
				step: 0.25,
			},
			axe: {
				name: "axe",
				label: "Axe",
				type: "number",
				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				default: "",
				// default: '0',
				placeholder: "0",
				step: 5,
			},
			qty: {
				name: "qty",
				min: "0",
				label: "Quantité",

				wrapper: {
					tag: "div",
					attrs: { className: "col-sm-2" },
				},
				type: "number",
				default: "1",
			},
		},
	},
};

const addOrderForm = ({ values, clients = [] }) => {
	console.log(values);
	//let norm = norms[values && values.norm_group && values.norm_group.norm ? values.norm_group.norm : 'AXELLE38'];

	if (!values["blue_group"])
		values.blue_group = { blue: false, pair: false };

	let norm =
		norms[
			values && values.norm_group && values.norm_group.norm
				? values.norm_group.norm
				: {}
		];

	const category = values.category_group
		? values.category_group.norm
		: "LENTILLE SOUPLE";
	let form = {
		name: "addOrderForm",
		id: "addOrderForm",
		actions: "",
		method: "",
		groups: {
			client_group: {
				name: "client_group",
				restricted: "orders:create:for-client",
				inputs: {
					userId: {
						name: "userId",
						label: "Client",
						type: "select",
						values:
							clients.length > 0
								? [
										{
											label:
												"Choisir un client",
											value: "",
										},
										...clients.map(
											(c) => {
												let name = c.partner
													? `${c.name} (${c.partner.name})`
													: c.name;
												return {
													label: name,
													value:
														c._id,
												};
											}
										),
								  ]
								: [],
					},
				},
			},

			category_group: {
				name: "category_group",
				wrapper: { tag: "div" },
				inputs: {
					category: {
						name: "norm",
						wrapper: {
							tag: "div",
							attrs: {
								className: "form-control-cat",
							},
						},
						type: "chips",
						multiple: false,
						values: categories.map((n) =>
							n === "LENTILLE TORIQUE RIGIDE" ||
							n === "LENTILLE TORIQUE SOUPLE" ||
							n === "LENTILLE COULEUR TORIQUE"
								? {
										label: n,
										value: n,
										className:
											"cat-chip large-cat",
								  }
								: {
										label: n,
										value: n,
										className:
											"cat-chip",
								  }
						),
					},
				},
			},

			norm_group: {
				name: "norm_group",
				wrapper: { tag: "div" },
				inputs: {
					norm: {
						name: "norm",
						wrapper: {
							tag: "div",
							attrs: {
								className: "form-control-chips",
							},
						},
						type: "chips",
						multiple: false,
						values: Object.keys(norms)
							.filter(
								(n) =>
									norms[n].category ===
									category
							)
							.map((n) => ({
								label: norms[n].ref,
								value: n,
								className: norms[n].chipcolor,
							})),
					},
				},
			},

			left_group: {
				name: "left_group",
				wrapper: {
					tag: "div",
					attrs: { className: "row params-row" },
				},
				inputs: norm
					? generateParamsFromLimit("left", norm.limits)
					: "",
			},
			right_group: {
				name: "right_group",
				wrapper: {
					tag: "div",
					attrs: { className: "row params-row" },
				},
				inputs: norm
					? values.blue_group.pair
						? generateParamsFromLimit(
								"right",
								norm.limits
						  )
						: ""
					: "",
			},
		},
	};

	if (norm) {
		form.groups.blue_group = {
			name: "blue_group",
			wrapper: {
				tag: "div",
				attrs: {
					className: "text-right pt-2 checkboxes",
				},
			},

			inputs: {
				pair: {
					name: "pair",
					wrapper: {
						tag: "div",
						attrs: {
							className:
								"mr-2  custom-control custom-checkbox",
						},
					},
					type: "checkbox",
					label: "Paire",
					className: "custom-control-input ",
					id: "pair-input",
				},
			},
		};
		if (norm.blue) {
			form.groups.blue_group = {
				name: "blue_group",
				wrapper: {
					tag: "div",
					attrs: {
						className: "text-right pt-2 checkboxes",
					},
				},

				inputs: {
					blue: {
						name: "blue",
						wrapper: {
							tag: "div",
							attrs: {
								className:
									"mr-2  custom-control custom-checkbox",
							},
						},
						type: "checkbox",
						label: "Bleutée",
						className: "custom-control-input ",
						id: "blue-input",
					},
					pair: {
						name: "pair",
						wrapper: {
							tag: "div",
							attrs: {
								className:
									"mr-2  custom-control custom-checkbox",
							},
						},
						type: "checkbox",
						label: "Paire",
						className: "custom-control-input ",
						id: "pair-input",
					},
				},
			};
		}
	}
	return form;
};

const generateParamsFromLimit = (side, limits, disabled = false) => {
	return Object.keys(limits).reduce(
		(acc, cur) => ({
			...acc,
			[`${side}_${cur}`]: {
				...limits[cur],
				name: `${side}_${cur}`,
				disabled,
			},
		}),
		{}
	);
};

const shouldCheck = (norm) => norms[norm].check;

const defaultValues = (user, { values, clients }) => {
	// let norm = Object.keys(norms)[0];

	//let default_norm_category = Object.keys(norms).filter(n => norms[n].category === category)[0]

	let norm =
		values &&
		values.norm_group &&
		values.norm_group.norm &&
		values.norm_group.norm.length > 0
			? values.norm_group.norm
			: {};

	let limits = norms[norm] ? norms[norm].limits : "";
	let defaults = {
		client_group: {
			userId: checkAbility(user, "orders:create:own")
				? user._id
				: "",
		},
		norm_group: { norm },
		left_group: Object.keys(limits).reduce(
			(acc, cur) => ({
				...acc,
				[`left_${cur}`]: limits[cur].default,
			}),
			{}
		),
		left_group: Object.keys(limits).reduce(
			(acc, cur) => ({
				...acc,
				[`right${cur}`]: limits[cur].default,
			}),
			{}
		),
		// right_group: {
		//     ...Object.keys(limits).reduce((acc, cur) => ({...acc, [`right_${cur}`]: limits[cur].default }), {}),
		//     right_qty: ["TORIQUE70", "TORIQUE38"].indexOf(norm.ref) > -1 ? '0' : '1'
		// }
	};
	if (norm[norm]) {
		if (norms[norm].blue) {
			defaults.blue_group = {
				blue: false,
			};
		}
	}

	return defaults;
};

const validators = (user, { clients, values }) => {
	let norm =
		values &&
		values.norm_group &&
		values.norm_group.norm &&
		values.norm_group.norm.length > 0
			? values.norm_group.norm
			: "AXELLE38";
	let errors = norms[norm].errors;

	let validators = {
		client_group: {
			userId: [
				(value) => {
					let possibleValues = [];

					if (!value || (value && value.length) < 1)
						return "Vous devez choisir un client";

					if (checkAbility(user, "orders:create:own"))
						possibleValues.push(user._id);

					if (
						checkAbility(
							user,
							"orders:create:for-client"
						)
					) {
						possibleValues = possibleValues.concat(
							clients.reduce(
								(acc, { _id }) => [...acc, _id],
								[]
							)
						);
					}

					return possibleValues.indexOf(value) > -1
						? false
						: "Vous devez choisir un client";
				},
			],
		},
		norm_group: {
			norm: [
				(value) =>
					value &&
					value.length > 0 &&
					Object.keys(norms).indexOf(value) > -1
						? false
						: "Vous devez choisir une norme",
			],
		},
		left_group: Object.keys(errors).reduce((acc, cur) => {
			if (errors[cur] && errors[cur].length > 0)
				return { ...acc, [`left_${cur}`]: errors[cur] };
			return acc;
		}, {}),
	};
	if (
		values &&
		values.right_group &&
		values.right_group.right_qty &&
		values.right_group.right_qty != 0 &&
		values.blue_group.pair
	) {
		validators.right_group = Object.keys(errors).reduce((acc, cur) => {
			if (errors[cur] && errors[cur].length > 0)
				return { ...acc, [`right_${cur}`]: errors[cur] };
			return acc;
		}, {});
	}
	return validators;
};

const updateGroups = (groups, { group, input, value }) => {
	let new_groups = groups;
	if (group == "norm_group" && input == "norm") {
		value = value && value.length > 0 ? value : "AXELLE38";

		new_groups = GenericForm.updateGroups(new_groups, {
			group,
			input,
			value,
		});

		["left", "right"].map((g) => {
			Object.keys(norms[value].limits).map((l) => {
				let lg = `${g}_${l}`;
				let new_value =
					lg == "right_qty"
						? "1"
						: norms[value].limits[l].default; // setting right qty to 0
				// let new_value = lg == 'right_qty' && ["TORIQUE70", "TORIQUE38"].indexOf(norms[value].ref) > -1 ? 0 : norms[value].limits[l].default; // setting right qty to 0
				// let new_value = norms[value].limits[l].default;
				new_groups = GenericForm.updateGroups(new_groups, {
					group: `${g}_group`,
					input: lg,
					value: new_value,
				});
			});
		});

		if (norms[value].blue) {
			new_groups.blue_group = { blue: false };
		}
		new_groups.blue_group = { pair: false };
	} else {
		// if(isNaN(value)){

		new_groups = GenericForm.updateGroups(new_groups, {
			group,
			input,
			value,
		});

		if (
			group === "left_group" &&
			new_groups.norm_group.norm === "SCLERALE TORIQUE"
		) {
			if (new_groups.left_group.left_d === "15.50") {
				norms["SCLERALE TORIQUE"].limits.tor.values = [
					"",
					"50",
					"100",
				];
			} else {
				norms["SCLERALE TORIQUE"].limits.tor.values = [
					"",
					"50",
					"100",
					"150",
					"200",
				];
			}
		}

		if (
			group === "left_group" ||
			(group === "right_group" &&
				new_groups.norm_group.norm === "TORIQUE70PRS")
		) {
			if (
				["8.30", "8.40", "8.50", "8.60", "8.70"].includes(
					new_groups.left_group.left_r
				)
			) {
				norms["TORIQUE70PRS"].limits.d.values = [
					"",
					"13.50",
					"14.50",
				];

				norms["TORIQUE70PRS"].errors.d = [
					(value) =>
						value &&
						value.length > 0 &&
						!isNaN(value) &&
						[14.5, 13.5].indexOf(Number(value)) > -1
							? false
							: true,
				];
			} else {
				norms["TORIQUE70PRS"].limits.d.values = [
					"",
					"13.50",
					"14.00",
					"14.50",
				];
				norms["TORIQUE70PRS"].errors.d = [
					(value) =>
						value &&
						value.length > 0 &&
						!isNaN(value) &&
						[14.0, 14.5, 13.5].indexOf(Number(value)) >
							-1
							? false
							: true,
				];
				norms["TORIQUE70PRS"].limits.d.default = "14.00";
			}
		}

		if (group == "category_group") {
			let cat = new_groups.category_group
				? new_groups.category_group.norm
				: "LENTILLE SOUPLE";
			let default_category = "";
			new_groups.norm_group.norm = default_category;
			new_groups.left_group = {};
		}

		// }else{
		//     new_groups = GenericForm.updateGroups(new_groups, {group, input, value: Number.parseFloat(value).toFixed(2)});
		// }
	}

	// Show the default Limits of the selected category Done By Yacine

	return new_groups;
};

// const updateGroups = (groups, {group, input, value}) => {
//     let new_groups = groups;
//     if(group == 'norm_group' && input == 'norm'){
//         value = value && value.length > 0 ? value : 'AXELLE38';
//         new_groups = GenericForm.updateGroups(new_groups, {group, input, value});

//         ['left', 'right'].map( (g) => {
//             Object.keys(norms[value].limits).map( (l) => {
//                 let lg = `${g}_${l}`;
//                 new_groups = GenericForm.updateGroups(new_groups, {group: `${g}_group`, input: lg, value: norms[value].limits[l].default})
//             });
//         });

//     }else{
//         new_groups = GenericForm.updateGroups(new_groups, {group, input, value});
//     }

//     return new_groups
// }

const formToOrder = (order) => {
	let items = {};
	let limits = norms[order.norm_group.norm].limits;

	Object.keys(limits).reduce((acc, limit) => {
		let sides = order.blue_group.pair ? ["left", "right"] : ["left"];
		sides.map((side) => {
			items[`${side}_${limit}`] =
				order[`${side}_group`][`${side}_${limit}`];
		});
	}, {});

	let res = {
		userId: order.client_group.userId,
		norm: order.norm_group.norm,
		items: [items],
	};

	if (norms[res.norm].blue) res.blue = order.blue_group.blue;

	return res;
};

const orderToForm = (order) => {
	let res = {};
	if (!order.items) return {};

	if (order.items[0] === undefined) return {};

	order.items = order.items[0];

	res = {
		client_group: { userId: order.userId },
		norm_group: { norm: order.norm },
		left_group: Object.keys(order.items).reduce(
			(acc, cur) =>
				cur.indexOf("left") > -1
					? { ...acc, [cur]: order.items[cur] }
					: acc,
			{}
		),
		right_group: order.pair
			? Object.keys(order.items).reduce(
					(acc, cur) =>
						cur.indexOf("right") > -1
							? { ...acc, [cur]: order.items[cur] }
							: acc,
					{}
			  )
			: "",
	};

	if (norms[order.norm].blue) {
		res.blue_group = { blue: order.blue };
	}
	if (order.pair) {
		res.blue_group = { pair: true };
	}

	return res;
};

const extractors = (values) => {
	return {
		left_group: {
			value: (group) => group,
		},
	};
};

addOrderForm.formToOrder = formToOrder;
addOrderForm.orderToForm = orderToForm;
addOrderForm.defaultValues = defaultValues;
addOrderForm.validators = validators;
addOrderForm.updateGroups = updateGroups;
addOrderForm.norms = norms;
addOrderForm.extractors = extractors;
addOrderForm.shouldCheck = shouldCheck;

export default addOrderForm;
